<template>
  <div>
    <div class="design-width content">
      <h1 class="hidden">Culture</h1>
      <div v-if="contents">
        <div ref="grid" class="culture-grid">
            <div ref="img" class="culture-item" v-for="(item, i) in contents" :key="i" :style="{'height': `${scaleFactor * item.image.height}px`, '--rnd': `${rnd()}`}">
              <component class="culture-item__block" :is="(() => (item.link ? 'a': 'span'))()" :href="(() => (item.link ? item.link: null))()" target="_blank">
                <img class="culture-item__image" :src="item.image.url" :alt="item.title" />
              </component>
            </div>
        </div>
      </div>
    </div>
    <div class="spinner">
      <div class="frame">
        <div class="strip">
          <div class="strip-a">H</div>
          <div class="strip-b">O</div>
          <div class="strip-c">C</div>
          <div class="strip-d">H</div>
          <div class="strip-e">S</div>
          <div class="strip-f">P</div>
          <div class="strip-g">A</div>
          <div class="strip-h">N</div>
          <div class="strip-i">N</div>
          <div class="strip-j">U</div>
          <div class="strip-k">N</div>
          <div class="strip-l">G</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import {ax, baseURL} from "../js/api";

export default Vue.extend({
  components: {

},
  data: function () {
    return {
      contents: [{image: '', title: ''}],
      scaleFactor: 0.75, 
    }
  },
  created() {
    ax.get(`${baseURL}/api/culture`).then(response => {
      this.contents = response.data.data;
    })

  },
  mounted() {
    this.observer = new IntersectionObserver(this.intersect, {
      threshold: (new Array(100)).fill('').map((d,i) => i/100),
      //rootMargin: '-300px 0px',
    });

    setTimeout(() => {
      Array.from(this.$refs.grid.querySelectorAll('.culture-item')).forEach($el => {
        this.observer.observe($el);
      });
    }, 500);
  },
  beforeDestroy() {

  },
  methods: {
    rnd: function () {
      return Math.random();
    },
    intersect: function (items) {
      items.forEach(item => {
        if (item.isIntersecting) {
          item.target.style.setProperty('--ratio', item.intersectionRatio);
        } else {
          item.target.classList.remove('intersects');
        }
      });
    },

    speed: function() {

    }
  }
});
</script>

<style lang="scss">
@import '../styles/_toolbox';

.spinner {
  pointer-events: none;
  position: fixed;
  top: 50vh;
  left: 50%;
	text-align:center;
	margin:0 auto;
	perspective: 1900px;
  font-size: 60px;

  z-index: -2;

  @include viewport(phone) {
    transform: scale(.6);
  } 
}
	
.frame {
  transform-origin: 50% 50% 100%;
	transform-style: preserve-3d;
  transform: rotateX(35deg) rotateY(45deg) rotateZ(calc(360deg * var(--scrollProgress) + 25deg)) translate3d(-100%,-50%, 0px);
}
	
.strip {
  transform-style: preserve-3d;
  transform: rotateY(calc(var(--scrollProgress) * 1360deg));
  will-change: transform;
  transform-origin: 50% 50% 50%;
}

.strip div {
  position: absolute; 
  transform-origin: 50% 50% 0px;
}

.strip-a {
	   transform: rotateY(0deg) translateZ(130px) scaleY(4);
}
		
.strip-b {
	   transform: rotateY(30deg) translateZ(130px) scaleY(4);
}
	
.strip-c {
	   transform: rotateY(60deg) translateZ(130px) scaleY(4);
}
	
.strip-d {
	   transform: rotateY(90deg) translateZ(130px) scaleY(4);
}	
	
.strip-e {
	   transform: rotateY(120deg) translateZ(130px) scaleY(4);
}	
	
.strip-f {
	   transform: rotateY(150deg) translateZ(130px) scaleY(4);
}
	
.strip-g {
	   transform: rotateY(180deg) translateZ(130px) scaleY(4);
}
	
.strip-h {
	   transform: rotateY(210deg) translateZ(130px) scaleY(4);
}	
	
.strip-i {
	   transform: rotateY(240deg) translateZ(130px) scaleY(4);
}	
	
.strip-j {
	   transform: rotateY(270deg) translateZ(130px) scaleY(4);
}
	
.strip-k {
	   transform: rotateY(300deg) translateZ(130px) scaleY(4);
}
		
.strip-l {
	   transform: rotateY(330deg) translateZ(130px) scaleY(4);
}	
	
</style>