<template>
  <div class="design-width content" :style="{'--project-color': color, '--project-color--contrast': contrastColor}">
    <div class="content__inner">
    <div ref="intro" class="intro" :style="{background: `url(${contents.data.image.url})`}">
      <img class="intro__sizer" :src="contents.data.image.url" :alt="contents.data.client" />

      <div ref="intromask" class="intro__mask" :style="{backgroundImage: `url(${contents.data.image.url})`}">
        <video
          v-if="contents.data.video"
          class="intro__video"
          :src="contents.data.video"
          autoplay 
          :loop="true"
          muted
          playsinline
        ></video>
      </div>
    </div>

    <div class="grid">
      <div class="grid__cell grid__cell--1/2 grid__cell--1/1@phone">
        <h1 
          class="t-title t-title--case t-hero--small"
          ref="title"
        >
          <strong v-html="contents.data.title"></strong>
          <span v-html="contents.data.client"></span>
        </h1>
      </div>
    </div>

    <div 
      v-for="(content, index) in contents.data.content" 
      :key="index"
      :class="getBlockWrapClass(
        content.rowWidth, 
        content.rowOrientation,
        content.blocks.length,
        content.marginTop
      )"
      class="content__block"
    >
      <template v-for="(block, i) in content.blocks">
        <div 
          :key="i + block.text"
          v-if="block.type === 'text'" 
          
          :class="getBlockClass({
            fontSize: block.fontSize,
            totalBlocks: content.blocks.length,
            colored: block.coloredBackground,
          })"
          v-observe-visibility="visibilityChanged"
        >
          <p :class="{'filled': block.coloredBackground}" v-html="block.text"></p>
        </div>
        <Video 
          v-bind:key="i + block.url"
          v-else-if="block.type === 'video'" 
          :src="block.url" 
          :autoplay="true" 
          :loop="true"
          :class="getBlockClass({
            totalBlocks: content.blocks.length,
          })"
          v-observe-visibility="visibilityChanged"
        ></Video>
        <Video
          v-bind:key="i"
          v-else-if="block.type === 'vimeo'" 
          :vimeoID="parseInt(block.vimeoId)"
          :class="getBlockClass({
            totalBlocks: content.blocks.length,
          })"
          v-observe-visibility="visibilityChanged"
        ></Video>
        <aside 
          v-bind:key="i"
          v-else-if="block.type === 'titleAndText'" 
          :class="getBlockClass({
            fontSize: block.fontSize,
            totalBlocks: content.blocks.length,
          })"
          v-observe-visibility="visibilityChanged"
        >
          <div class="grid__text">
            <div :class="{'filled' : block.coloredBackground}">
              <h2 class="t-hero" v-html="block.title"></h2>
              <p class="t-hero t-hero--small"  v-html="block.text"></p>
            </div>
          </div>
        </aside>
        <div 
          v-bind:key="i"
          v-else-if="block.type === 'image'"
          :class="getBlockClass({
            totalBlocks: content.blocks.length,
          })"
          v-observe-visibility="visibilityChanged"
        >
          <img
            :src="block.image.url"
            :alt="block.image.title"
          />
        </div>
        <div 
          v-bind:key="i"
          v-else-if="block.type === 'spacer'"
          :class="getBlockClass({
            totalBlocks: content.blocks.length,
          })"
          v-observe-visibility="visibilityChanged"
        >
        </div>
        
      </template>

      <!--pre><code>{{content}}</code></pre-->
    </div>
    </div>
    <aside class="related">
      <h2 class="t-hero">More Work</h2>
      <div class="grid grid--center grid--related">
      <div v-for="work in related" :key="work.slug" class="grid__cell grid__cell--1/2 grid__cell--1/1@phone">
        <Thumb
          :route=work.slug
          :imgsrc=work.image.url
          :imgalt=work.title
          :title=work.title
          :subtitle=work.client
          :related='true'
        >
        </Thumb>
      </div>
      </div>
    </aside>
  </div>
</template>

<script>
import Vue from "vue";
import Video from "../components/video.vue";
import Thumb from "../components/project-thumb.vue";
import {ax, baseURL} from "../js/api";
import anime from 'animejs/lib/anime.es.js';

function bestContrast(hex) {
    if (hex.indexOf('#') === 0) {
        hex = hex.slice(1);
    }
    // convert 3-digit hex to 6-digits.
    if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }

    if (hex.length !== 6) {
        throw new Error('Invalid HEX color.');
    }

    let r = parseInt(hex.slice(0, 2), 16),
        g = parseInt(hex.slice(2, 4), 16),
        b = parseInt(hex.slice(4, 6), 16);

    // http://stackoverflow.com/a/3943023/112731
    return (r * 0.299 + g * 0.587 + b * 0.114) > 186
        ? 'var(--c-text)'
        : 'var(--c-bg)';
    
}

export default Vue.extend({
  components: {
    Video,
    Thumb,
  },
  data: function () {
    return {
      related: [],
      caseToCaseAnimation: false,
      contents: {
        data: {
          video: '',
          image: {url: ''},
          title: '',
        },
      },
      color: '#000000',
    }
  },
  computed: {
    contrastColor: function() {
      return bestContrast(this.color);
    },
  },
  created() {
    this.contents = {
        data: {
          video: '',
          image: {url: ''},
          title: '',
        },
      };
    this.getContents();
  },
  mounted() {
    this.animate();
  },
  watch: {
    $route(to, from) {
      if(to.name === from.name) {
        this.animate(true);
        this.getContents();
      }
    }
  },
  methods: {
    getContents () {
      this.caseSlug = this.$route.params.case;

      ax.get(`${baseURL}/api/work/${this.caseSlug}`).then(response => {
      // handle success
        this.contents = response;
        this.color = response.data.color;
      });

      ax.get(`${baseURL}/api/work`).then(response => {
      // handle success
        let related = [...response.data.data].filter(d => 
          d.slug !== this.caseSlug
        ).sort(function() { return .5 - Math.random() });
        this.related = related.slice(0, 2);
      });
    },
    animate(jumpToTop) {
      if (!this.$route.params.hasOwnProperty('cloneDom')) return;
      
      const titleRect = this.$refs.title.getBoundingClientRect();
      
      const cloneDom = this.$route.params.cloneDom;
      const cloneTitleRect = cloneDom.$cloneTitle.getBoundingClientRect();

      cloneDom.$clone.style.position = 'absolute';

      const tl = anime.timeline();
      const scrollTop = window.scrollY || window.pageYOffset || document.body.scrollTop + (document.documentElement && document.documentElement.scrollTop || 0);
      
      window.scrollTo(0, 0);

      tl.add({
        targets: cloneDom.$cloneTitle,
        translateY: scrollTop + titleRect.top - cloneTitleRect.top,
        duration: 400,
        easing: 'cubicBezier(0.52, 0.01, 0.16, 1)',
      });

      tl.add({
        targets: cloneDom.$cloneMask,
        scaleX: 0,
        duration: 400,
        easing: 'cubicBezier(0.52, 0.01, 0.16, 1)',
      }, '-=200');
      /*tl.add({
        targets: this.$refs.intromask,
        opacity: 0,
        duration: 400,
        easing: 'cubicBezier(0.52, 0.01, 0.16, 1)',
      }, '-=200');*/

      tl.finished.then(() => {
        cloneDom.$clone.parentNode.removeChild(cloneDom.$clone);
      });
      
    },
    visibilityChanged (isVisible, e) {
      if(isVisible) {
        e.target.classList.add('is-inviewport');
      }
    },
    getBlockClass(attrs) {
      const classes = [];

      classes.push('will-appear');

      if (attrs.hasOwnProperty('colored') && attrs.colored === true) {
        //classes.push('filled');
      }

      if (attrs.hasOwnProperty('fontSize')) {
        classes.push(`t-${attrs.fontSize}`);
      }
      
      if (attrs.hasOwnProperty('totalBlocks') && attrs.totalBlocks > 1) {
        classes.push('grid__cell');
        classes.push(`grid__cell--1/${attrs.totalBlocks}`);
        classes.push(`grid__cell--1/1@phone`);
        if (attrs.totalBlocks > 2) {
          classes.push(`grid__cell--1/1@phone`);
        }
      }

      return classes;
    },
    getBlockWrapClass(rowWidth, vAlign, totalBlocks, stack){
      const classes = [];

      if(stack && stack !== 'normal') {
        classes.push(`content__block--stack-${stack}`);
      }

      if (totalBlocks > 1) {
        classes.push('grid');
      }

      if (vAlign == 'bottom') {
        classes.push(`grid--end`);
      } else if (vAlign == 'center') {
        classes.push(`grid--center`);
      }

      if (rowWidth !== 'normal') {
        classes.push(`content__block--${rowWidth}`);
      }
      
      return classes;
    }
  },
})
</script>