<template>
  <div class="header" :class="{ 'header--nobackground': noBackground, 'header--expanded': expanded, 'header--static': isStatic, 'header--showcontact': showContact }">
    <Logo :isMini='expanded || isStatic' :isMainTitle='isStatic'></Logo>
    <nav class="navigation" :class="{'navigation--noClose': hideClose}" aria-label="Navigation">
      <ul class="navigation__list">
        <li @click="toggle" class="navigation__item" v-for='navItem in nav' :key="navItem.label" v-if="!navItem.exclude">
          <router-link class="navigation__link" :to="navItem.path">{{navItem.label}}</router-link>
        </li>
        <li class="navigation__item">
          <a @click.prevent="toggleContact" href="#contact" class="navigation__link">Kontakt</a>
        </li>
      </ul>
    </nav>
    <button title="Toggle Site Navigation" aria-label="Toggle Site Navigation" class="header__burger" :class="{'header__burger--hide': hideClose}" @click="toggle(true)">
      <svg viewBox="0 0 30 30" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <line x1="1" y1="6" x2="29" y2="6" id="Line" stroke="#000000" stroke-width="5"></line>
        <line x1="1" y1="15" x2="29" y2="15" id="Line-Copy" stroke="#000000" stroke-width="5"></line>
        <line x1="1" y1="24" x2="29" y2="24" id="Line-Copy-2" stroke="#000000" stroke-width="5"></line>
      </svg>
    </button>
    <div :class="{'header__intro--visible': showHeaderClaim, 'header__intro--static': isStatic}" class="header__intro">
      <div v-if="isStatic" v-html="homeText">
      </div>
      <div v-html="animatedFooterText">
      </div>
    </div>
    <div v-if="contact" :class="{'header__intro--visible': showHeaderClaim}" class="header__contact" >
      <address v-html="contact.address"></address>
      <aside class="header__social" aria-label="Social Media">
        <a target="_blank" href="https://vimeo.com/user10320938">Vimeo</a>
        <a target="_blank" href="https://www.facebook.com/Hochspannung.Zurich/">Facebook</a>
        <a target="_blank" href="https://www.instagram.com/hochspannung_/">Instagram</a>
        <a target="_blank" class="header__gap" href="https://hochspannung.us17.list-manage.com/subscribe?u=a222156737bd72eedfdf1c07a&id=b31158c076">Newsletter</a>
      </aside>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import Logo from "./logo.vue";
import {ax, baseURL} from "../js/api";

export default {
  components: {
    Logo,
  },
  data () {
    return {
      expanded: false,
      observeScroll: false,
      loadTime: 400,
      showHeaderClaim: true,
      showContact: false,
      homeText: null,
      forceRender: 0,
      noBackground: false,
      contact: {},
      hideClose: false,
      animatedFooterText: ''
    }
  },
  watch: {
    '$route.path' (to, from) {
      this.observeScroll = false;

      setTimeout(() => {
        this.observeScroll = true;
      }, this.loadTime);
    },
    showContact (show) {
      if (show) {
        document.documentElement.classList.add('footer-contanct-visible');
      } else {
        document.documentElement.classList.remove('footer-contanct-visible');
      }
    },
    footerText (text) {
      setTimeout(() => {
        this.animatedFooterText = text;
      },1000);
    }
  },

  props: {
    initiallyExpanded: Boolean,
    nav: Array,
    isStatic: Boolean,
    headerText: String,
    footerText: String,
    homeReady: Boolean,
  },

  methods: {
    toggle: function (isBurger = false) {
      if (this.isStatic) return;
      
      this.expanded = !this.expanded;

      if( this.expanded ) {
        this.$main.classList.add('is-expanded');
        this.showHeaderClaim = true;
      } else {
        this.observeScroll = false;
        this.$main.classList.remove('is-expanded');
        this.showContact = false;

        if (!isBurger) {
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });

          setTimeout(() => {
            this.observeScroll = true;
          }, 300);
        }
      }
    },
    toggleContact: function () {
      this.showContact = !this.showContact;
    },
    scrollObserver: function() {
      this.$main = document.querySelector('.main');
      const $footer = document.querySelector('.js-footer');
      
      this.intersectionObserver = new IntersectionObserver((entries, observer) => {
        if (!this.observeScroll) return;

        if (
          this.expanded
          && (!entries || !entries[0].isIntersecting)
        ) {
          this.expanded = false;
          this.$main.classList.remove('is-expanded');
          this.noBackground = false;
          this.hideClose = false;
        } else if (entries && entries[0].isIntersecting) {
          this.expanded = true;
          this.showHeaderClaim = false;
          this.noBackground = true;
          this.hideClose = true;
        }
      }, {
        threshold: 0.7
      });

      this.intersectionObserver.observe($footer);
    }
  },

  mounted: function () {
    this.scrollObserver();

    const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

    if ( isFirefox ) {
      let lastTop = 0;

      window.addEventListener('scroll', e => {
        if (window.scrollTop < lastTop - 20) {
          this.$main.classList.remove('is-expanded');
          this.showContact = false;
          this.expanded = false;
          this.hideClose = false;
        }
        lastTop = window.scrollTop;
      });
    }
      
    ax.get(`${baseURL}/api/home`).then(response => {
      this.forceRender += 1;
      this.homeText = response.data.headerText;
    });

    ax.get(`${baseURL}/api/contact`).then(response => {
      this.contact = response.data;
    });

    setTimeout(() => {
      this.observeScroll = true;
    }, this.loadTime);
  }
}
</script>

<style lang="scss">
  @import '../styles/_toolbox';
  
  .header {
    z-index: 3;
    position: fixed;
    top: 0; left: 0; right: 0;
    height: 6rem;
    transition: height 1200ms cubic-bezier(0.52, 0.01, 0.16, 1);
    overflow: hidden;

    & > * {
      pointer-events: none;
    }

    & > .logo,
    & > .header__burger {
      pointer-events: all;
    }

    &--expanded, 
    &--static {
      & > * {
        pointer-events: all;
      }
    }

    &__social {
      margin-top: var(--size-grid-gutter);
      font-size: var(--size-font-size);

      a {
        margin-right: 2rem;
      }
    }

    &::before {
      content: '';
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      right: 0;
      bottom: 6.5rem;
      background: var(--c-bg--contrast);
      transition: transform 1200ms cubic-bezier(0.52, 0.01, 0.16, 1);
      transform: translateY(-101%);
    }

    &--nobackground {
      &::before {
        display: none;
      }
      .header__intro {
        visibility: hidden; 
      }
    }

    &--expanded,
    &.header--static {
      height: calc(100vh + 6.5rem);

      &::before {
        transform: translateY(0%);
      }
    }

    &.header--static {
      z-index: 4;

      & .header__intro {
        visibility: visible;
      }
    }

    &.header--static {
      position: relative;
      min-height: calc(100vh + 6.5rem);
      height: auto;

      @include viewport(phone) { 
        padding-bottom: 2rem;
        margin-bottom: 6rem;
        
        &::before {
          bottom: 0;  
        }
      }
    }

    &__contact,
    &__intro {
      @include viewport(phone) {
        position: relative;
        transform: translate(0, 50%);
        left: 0;
        top: 0;
        padding: 2rem;
      }

      //visibility: hidden;
      opacity: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 80%;
      transform: translate(-50%, 50%);
      font-size: 6.8rem;
      font-size: var(--size-font-size--hero);
      line-height: 1.1;
      transition: transform 1000ms cubic-bezier(0.52, 0.01, 0.16, 1), opacity 300ms linear;

      .header--expanded &,
      .header--static & {
        opacity: 1;
        transform: translate(-50%, -50%);
        transition: transform 1000ms cubic-bezier(0.52, 0.01, 0.16, 1) 700ms, opacity 300ms linear 1000ms;

        @include viewport(phone) {
          transform: translate(0, 0);
        }
      }

      .header--showcontact & {
        opacity: 0;
        transform: translate(0, 50%);
        transition: transform 1000ms cubic-bezier(0.52, 0.01, 0.16, 1) 0ms, opacity 300ms linear 200ms;
      }

      &--visible {
        visibility: visible;
      }

      &--static {
        font-size: var(--size-font-size--medium);
      }

      a {
        text-decoration: underline;
      }
    }

    &__contact {

      font-size: 8rem;

      @include viewport(phone) {
        font-size: var(--size-font-size--medium);
      }
      
      a {
        white-space: nowrap;
      }
      
      .header--expanded &,
      .header--static & {
        opacity: 0;
        transform: translate(-50%, 50%);
        transition: transform 1000ms cubic-bezier(0.52, 0.01, 0.16, 1) 300ms, opacity 300ms linear 400ms;
        
        @include viewport(phone) {
          position: absolute;
          transform: translate(0, 0);
          left: 0;
          top: 16rem;
          padding: 2rem;
        }
      }

      .header--showcontact & {
        opacity: 1;
        transform: translate(-50%, -50%);

        @include viewport(phone) {
          transform: translate(0, 0);
        }
      }
    }

    &__burger {
      background: none;
      border: 0;
      border-radius: 0;
      position: absolute;
      top: 0;
      right: 0;
      padding: 2rem;
      outline: none;
      cursor: pointer;

      &--hide {
        visibility: hidden;
        z-index: -2;
        pointer-events: none;
      }

      .header--static & {
        display: none;
      }

      svg {
        display: block;
        height: 2.6rem;
        width: 2.6rem;

        line:nth-child(2) {
          stroke-dasharray: 30 30;
          transition: 200ms stroke-dashoffset ease-in;
        }
        line:nth-child(1),
        line:nth-child(3) {
          transition: 200ms transform ease-out 100ms;
        }

        .header--expanded & {
          line:nth-child(2) {
            stroke-dashoffset: 30;
          }

          line:nth-child(1) {
            transform: translate(6px, -2px) rotate(45deg);
          }

          line:nth-child(3) {
            transform: translate(-15px, 7px) rotate(-45deg);
          }
        }
      }

    }
  }

  .navigation {
    @include viewport('phone') {
      position: relative;
      right: 0;
    }

    position: absolute;
    top: 0;
    right: 1.6rem + 4.6rem; // burger width

    &--noClose {
      right: 1.6rem;
    } 
    
    .header--static & {
      right: 0;
    }

    .hipster-mode & {
      right: calc(20rem + 4rem);
    }

    &__list {
      display: flex;
      overflow: hidden;
    }

    &__item {
      opacity: 0;
      transform: translateY(100%);
      transition: 300ms transform cubic-bezier(0.16, 0.84, 0.44, 1), 200ms opacity;
      
      .header--static &,
      .header--expanded & {
        opacity: 1;
        transform: translateY(0);
        @for $i from 1 through 6 {
          &:nth-child(#{$i}) {
            transition-delay: 800ms - $i * 50ms;
           }
        }
      }
    }

    &__link {
      display: block;
      padding: 2rem;
      height: 2.5rem;
      line-height: 2.5rem;
    }
  }
</style>