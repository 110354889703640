<template>
  <div v-if="vimeoID" 
      class="video">
    <vue-plyr :options="options">
      <div ref="player" data-plyr-provider="vimeo" :data-plyr-embed-id="vimeoID"></div>
    </vue-plyr>
  </div>

  <div v-else 
      class="video"
      :class="{'video--collapsed': collapse}">
    <video 
      @mouseleave="pause" 
      @mouseover="play"
      :autoplay="autoplay" 
      :loop="loop"
      class="video__element"
      :poster="poster"
      :muted="muted"
      ref="player"
      playsinline
    > 
      <source :src="src" type="video/mp4" />
    </video>
  </div>
</template>

<script>
// https://www.npmjs.com/package/vue-plyr
import VuePlyr from 'vue-plyr';
import Vue from "vue";

Vue.use(VuePlyr);

export default {
  props: {
    src: String,
    autoplay: Boolean,
    loop: Boolean,
    playOnHover: Boolean,
    vimeoID: Number,
    poster: String,
    muted: Boolean,
    collapse: Boolean,
  },
  mounted () {
    if (!this.vimeoID) {
      this.$refs.player.addEventListener('ended', this.videoEnd, false);
    }
  },
  watch: {
    collapse: function (val) {
      if (val) {
        this.$refs.player.pause();
      }
    }
  },
  computed: {
    options: function () {

      return {
        controls: `
          <button type="button" class="video__play" data-plyr="play" aria-label="Play, YT5_Video_1_Banker"><svg role="presentation" focusable="false"><use xlink:href="#icon-videoplay"></use></svg><span class="plyr__sr-only">Play</span></button>
          `
      }
    },
  },
  methods: {
    videoEnd: function () {
      this.$emit('ended');
    },
    play: function (e) {
      if (this.playOnHover) {
        e.target.play();
      }
    },
    pause: function (e) {
      if (this.playOnHover) {
        e.target.pause();
      }
    }
  }
}
</script>

<style lang="scss">
  @import 'vue-plyr/dist/vue-plyr.css';

  .video {
    &__play {
      height: 20%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 10;
      display: block;
      background: none;
      border: 0;
      outline: none;

      svg {
        fill: #fff;
        height: 100%;
        width: auto;
      }

      .plyr--playing & {
        display: none;
      }
    }
    .is-home & {
      
      height: 100vh;
      z-index: 5;
      overflow: hidden;
      transition: 1000ms height cubic-bezier(0.52, 0.01, 0.16, 1);
      
      video {
        object-fit: cover;  
        height: 100%;
      }
      
      &--collapsed {
        height: 0; 
      }
    }

    position: relative;
    
    &__element {
      width: 100%;
      height: auto;
    }
  }

</style>