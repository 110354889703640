<template>
  <article
    class="team-member"
    v-bind:style="{ 'background-image': `url(${imgsrc})` }"
  >
    <a
      ref="root"
      :href="url || route || '#'" 
      class="team-member__link"
    > 
      <video :data-index="i" ref="video" class="team-member__video" muted loop autobuffer="autobuffer" preload="preload"></video>

      <img class="team-member__img team-member__img--hidden" v-bind:src="imgsrc" v-bind:alt="imgalt" />
      <div ref="header" class="team-member__header">
        <h2 
          class="team-member__title t-hero t-hero--small">
          <span v-html="title"></span>
          <strong class="team-member__subtitle" v-if="subtitle" v-html="subtitle">
          </strong>
        </h2>
        <svg class="team-member__icon icon">
          <use xlink:href="#icon-mail"></use>
        </svg>
      </div>
    </a>
  </article>
</template>

<script>
// https://codepen.io/meodai/pen/d7695bcddcb75435bde253d2f753d450
import axios from 'axios';
import { assertSwitchCase } from 'babel-types';

export default {
  props: {
    imgsrc: String,
    imgalt: String,
    videosrc: String,
    route: String,
    title: String,
    subtitle: String,
    url: String,
    i: Number,
    intersections: Array,
  },
  data: function () {
    return {
      boundingBox: { left:0, top:0, right:0, bottom:0, x:0, y:0, width:0, height:0 },
      ready: false,
      currentProgress: 0,
      targetProgress: 0,
      ticking: false,
    }
  },
  mounted () {
    let videodata;
    
    axios.get(`${this.videosrc}`, {
      responseType: "blob"
    })
    .then(response => {
      videodata = response.data;
      this.$refs.video.src = URL.createObjectURL(videodata);
    })
    .catch(function(error) {
        // handle error
        //console.log(error);
    });

    this.$refs.video.addEventListener('loadeddata', e => {
      URL.revokeObjectURL(videodata);
      this.$emit('loaded', e.target)
    },true);

    this.$nextTick(() => {
      this.ready = true;
    });    
  },
  methods: {
    anim: function () {
      if (this.intersections[this.i] && this.intersections[this.i] > 0.4 && !this.ticking) {
        this.ticking = true;

        this.$refs.video.play();

        this.timer = setTimeout(() => {
          this.$refs.video.pause();
          this.ticking = false;
        }, (this.$refs.video.duration * 1000) / 2);

      }
    },
  }
}
</script>

<style lang="scss">
  @import '../styles/_toolbox';

  .team-member {
    position: relative;
    background-size: cover;
    background-position: 50% 50%;
    margin-bottom: calc(var(--size-grid-gutter));
    overflow: hidden;

    @include viewport(phone) {
      height: 70vh;
    }

    &__header {
      z-index: 2;
      position: absolute;
      bottom: var(--size-bezel);
      right: var(--size-bezel);
      left: var(--size-bezel);
      background: var(--c-bg);
      padding: calc(var(--size-bezel) * .75);
      box-sizing: border-box;
    }

    &__link {
      display: block;
    }

    &__title {
      display: block;
      margin: 0 25% -0.1em 0;
      overflow: hidden;
      position: relative;
      z-index: 2;
      padding-bottom: 0.1em;
    }

    &__subtitle {
      display: block;
    }

    &__icon {
      position: absolute;
      top: 50%;
      right: calc(var(--size-bezel) * .75);
      transform: translateY(-50%);
      width: 5rem;
      height: auto;
      z-index: 2;
    }

    &__video {
      width: 100%;

      @include viewport(phone) {
        width: auto;
        height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    &__img {
      &--hidden {
        position: absolute;
        opacity: 0;
        width: 100%;
        height: 100%;
      }

      &--overlay {
        opacity: 0;
      }
    }
  }


  .team-member {
    transition: 200ms opacity linear;

    backdrop-filter: grayscale(100%);
    &::after {
      pointer-events: none;
      opacity: 0;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(255,255,255,.2);
      -webkit-backdrop-filter: grayscale(100%);
      backdrop-filter: grayscale(100%);
      transition: 200ms opacity linear;
      will-change: opacity;
    }
  }

  
</style>