




























import Vue from "vue";

export default {
  data () {
    return {
      designMode: false,
      $style: null,
      designTokens: [
        {
          name: '--c-brand',
          value: '#e70060',
          type: 'color',
        },{
          name: '--c-text',
          value: '#000000',
          type: 'color',
        },
        {
          name: '--c-interactive',
          value: '--c-text',
          type: 'var',
        },
        {
          name: '--c-bg',
          value: '#ffffff',
          type: 'color',
        },
        {
          name: '--c-bg--contrast',
          value: '--c-brand',
          type: 'var',
        },
        {
          name: '--size-grid-gutter',
          value: 2.2,
          type: 'size',
          color: 'red',
        },
        {
          name: '--size-content-stack',
          value: 6,
          type: 'size',
          color: 'pink',
        },
        {
          name: '--size-content-stack--large',
          value: 20,
          type: 'size',
          color: 'lime',
        },
        {
          name: '--size-design-gutter',
          value: 6.4,
          type: 'size',
          color: 'fuchsia',
        },
        {
          name: '--size-bezel',
          value: 4,
          type: 'size',
        },
        {
          name: '--size-font-size',
          value: 1.8,
          type: 'size'
        },
        {
          name: '--size-font-size--hero',
          value: 9,
          type: 'size'
        },
        {
          name: '--size-font-size--subhero',
          value: 3.6,
          type: 'size'
        },
      ]
    }
  },
  created: function () {
    this.$style = document.createElement('style');
    document.querySelector('head').appendChild(this.$style);
  },
  watch: {
    designTokens: {
      handler: function () {
        this.$style.innerHTML = `
          :root ${JSON.stringify(this.styles).replace(new RegExp('"', 'g'), '').replace(new RegExp(',', 'g'), ';')}
        `;
      },
      deep: true,
    }
  },
  computed: {
    styles: function () {
      const styles = {};

      this.designTokens.forEach(token => {
        let val = token.value;
        
        if (token.type === 'size') {
          val = token.value + 'rem';
        } else if (token.type === 'var') {
          val = `var(${token.value})`;
        }

        styles[token.name] = val;
      });

      return styles;
    }
  },
  methods: {
    toggle () {
      this.designMode = !this.designMode;
      if (this.designMode) {
        document.querySelector('body').classList.add('hipster-mode');
      } else {
        document.querySelector('body').classList.remove('hipster-mode');
      }
    }
  }
}
