import axios from 'axios';
const baseURL = 'https://craft.hochspannung.ch/';

axios.interceptors.request.use(config => {
  const params = new URLSearchParams(document.location.search.substring(1));
  const token = params.get('token');
  if (token) {
    config.url = config.url + '?token=' + token;
  }
  return config;
}, error => {
  return Promise.reject(error);
});

export {
  axios as ax,
  baseURL,
};