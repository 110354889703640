import Vue from 'vue';
import App from '../app.vue';
import router from './router';
import VueObserveVisibility from 'vue-observe-visibility';
import VueAnalytics from 'vue-analytics';
import VueMeta from 'vue-meta';

Vue.use(VueAnalytics, {
  id: 'UA-32003971-2',
  router,
});

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
});

window.scrollProgress = 0;
window.scrollHeight = Infinity;

Vue.use(VueObserveVisibility);

Vue.config.ignoredElements = [/lottie-\w*/];

new Vue({
  el: '#app',
  router,
  render: h => h(App)
});