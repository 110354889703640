<template>
  <div class="design-width content" v-if="contents.data">
    <div class="sectionIntro sectionIntro--services">
    
    <aside class="richtext">
      <h1 v-html="contents.data.title"></h1>
      <div class="sectionIntro__text" v-html="contents.data.text">
      </div>
    </aside>
    
    </div>
    
    <h1 class="t-title t-title--case t-hero hidden">What we offer</h1>
    <aside class="service" data-service v-for="(section, i) in contents.data.sections" v-bind:key="i">
      <div class="service__content">
        <div class="service__anim">
          <lottie-player
            class="service__player"
            data-player
            ref="animation"
            loop
            mode="normal"
            v-bind:src="uidToLottieURL(section.uid)"
          >
          </lottie-player>
        </div>
        <div class="service__text">
          <div class="richtext">
            <h1 v-html="section.title"></h1>
            <p v-html="section.description"></p>
            <div class="service__tags" v-html="section.keywords">
            </div>
          </div>
        </div>
      </div>
      <div class="service__related grid--center grid" v-if="section.work">
        <div v-for="work in section.work" :key="work.slug" class="grid__cell grid__cell--1/3 grid__cell--1/1@phone">
          <Thumb
            class="service__thumb"
            v-bind:route="work.slug" 
            v-bind:imgsrc="work.image.url"
            v-bind:imgalt="work.image.title"
            v-bind:title="work.title"
            v-bind:subtitle="work.client"
          ></Thumb>
        </div>
      </div>
    </aside>

    <div class="sectionOutro">
      <Video v-if="this.contents.data && this.contents.data.servicesVideo"
        class="sectionIntro__video" :src="this.contents.data.servicesVideo" 
        :autoplay="true" :loop="true" :muted="true">
      </Video>
      <aside class="richtext">
        <div class="sectionOutro__text" v-html="contents.data.text2">
        </div>
      </aside>
    </div>

    <Related v-if="contents.data.relatedLink" 
      :route="`/${contents.data.relatedLink.slug}`" 
      :title="contents.data.relatedLink.title" 
      :imgsrc="contents.data.relatedLink.image.url" 
      :imgalt="contents.data.relatedLink.image.title"
    />
  </div>
</template>

<script>
import Vue from "vue";
import {ax, baseURL} from "../js/api";
import Related from "../components/related.vue";
import Thumb from "../components/project-thumb.vue";

export default Vue.extend({
  components: {
    Related,
    Thumb,
  },
  data: function () {
    return {
      contents: {
        image: {url: ''},
        leadText: '',
        data: {},
      }
    }
  },
  watch: {
    contents: function () {
      this.$nextTick(function () {
        this.createObsevers();
      });
    }
  },  
  created() {
    ax.get(`${baseURL}/api/services`).then(response => {
      this.contents = response;
    })
    .catch(function (error) {
      //console.error(error);
    })
    .finally(function () {
      // always executed
    });
  },
  mounted: function () {

  },
  beforeDestroy() {
    const $sections = document.querySelectorAll('[data-service]');
    $sections.forEach($player => {
      observer.unobserve($player);
    });
    this.observer.disconnect();
  },
  methods: {
    createObsevers () {
      let observerOptions = {
        rootMargin: '0px',
        threshold: .25
      }

      this.observer = new IntersectionObserver(this.intersect, observerOptions);
      const $sections = document.querySelectorAll('[data-service]');

      $sections.forEach($player => {
        this.observer.observe($player);
      });
    },
    intersect(entries, observer) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target &&
          entry.target.querySelector('[data-player]') &&
          entry.target.querySelector('[data-player]').play();
        }
      });
    },
    breaksToList(html) {
      return `<ul><li>${html.replace(new RegExp('<br />', 'g'),'</li><li>')}</li></ul>`;  
    },
    uidToLottieURL(uid) {
      //console.log(uid);
      let url = '../animation/';
      let lotties = {
        '4ad1932e-316a-4f63-adff-fef0d4f7b69e': 'HS_Icon_Campaigning_Lottie.json',
        'b79a270f-129d-45c0-ae4c-39b94880c744': 'HS_Icon_Branding_Lottie.json',
        '14259e87-39ac-4996-b96d-2b972ff626cb': 'HS_Icon_Technology_Lottie.json',
      };

      if (lotties.hasOwnProperty(uid)) {
        url += lotties[uid];
      } else {
        url += 'HS_Icon_Campaigning_Lottie.json';
      }

      return url;
    }
  },
})
</script>