import home from '../pages/home';
import caseTmpl from '../pages/case';
import worksTmpl from '../pages/works';
import teamTmpl from '../pages/team';
import serviceTmpl from '../pages/service';
import cultureTmpl from '../pages/culture';
import fourOfourTmpl from '../pages/404';

export default [
  {
    label: 'Home',
    path: '/',
    component: home,
    exclude: true,
  },
  {
    label: 'Arbeiten',
    path: '/work',
    component: worksTmpl,
  },
  {
    label: 'Service',
    path: '/services',
    component: serviceTmpl,
  }, 
  {
    label: 'Team',
    path: '/team',
    component: teamTmpl,
  },
  {
    label: 'Culture',
    path: '/culture',
    component: cultureTmpl,
  },
  {
    name: 'work',
    path: '/work/:case',
    component: caseTmpl,
    exclude: true,
  },
  {
    name: '404',
    path: '*',
    component: fourOfourTmpl,
    exclude: true,
  },
];