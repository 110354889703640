<template>
  <aside
    class="related"
    ref="root"
  >
    <a 
      :href="route" 
      class="project-thumb__link" 
      v-on:click="goToDetail"
    >
      <h3 class="related__title">
        {{title}}
        <svg class="related__icon icon">
          <use xlink:href="#icon-arrow"></use>
        </svg>
      </h3>
      <img class="related__image" :src="imgsrc" :alt="imgalt"/>
    </a>
  </aside>
</template>

<script>
export default {
  props: {
    imgsrc: String,
    imgalt: String,
    route: String,
    title: String,
  },
  mounted () {
 
  },
  methods: {
    goToDetail: function () {}
  }
}
</script>

<style lang="scss">
  @import '../styles/_toolbox';

  .related {
    margin-top: var(--size-content-stack--large);
    padding: calc(var(--size-design-gutter) * .5) var(--size-design-gutter) var(--size-content-stack--large);
    margin-left: calc(var(--size-design-gutter) * -1);
    margin-right: calc(var(--size-design-gutter) * -1);
    margin-bottom: calc(-1 * var(--size-content-stack--large));
    background: var(--c-text);
    
    &, a, svg path {
      fill: var(--c-bg);
      color: var(--c-bg);
    }

    .project-thumb__header {
      fill: var(--c-text);
      color: var(--c-text);
      & > *:last-child {
        margin-bottom: -0.1em;
        padding-bottom: 0.1em;
      }
    }

    @include viewport(phone) {
      margin-top: var(--size-content-stack);
    }

    &__title {
      font-size: var(--size-font-size--hero);
      line-height: 1.11;
      width: 50%;
      margin-top: var(--size-content-stack);

      @include viewport(phone) {
        width: auto;
      }
    }

    &__image {
      display: block;
      margin-top: var(--size-content-stack);
      width: 60%;
      margin-left: 18%;

      @include viewport(phone) {
        width: 80%;
        margin-left: 8%;
      }
    }

    &__icon {
      display: inline-block;
      height: .6em;
      width: .6em;
    }
  }
</style>