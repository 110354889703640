<template>
  <article
    class="project-thumb"
    :style="{ 'background-image': `url(${imgsrc})` }"
    ref="root"
    :class="{'project-thumb--related': related}"
  >
    <a 
      :href="url || route || '#'" 
      class="project-thumb__link" 
      v-on:click="goToDetail"
    >
      <img class="project-thumb__img project-thumb__img--hidden" v-bind:src="imgsrc" v-bind:alt="imgalt" />
      <div ref="header" class="project-thumb__header">
        <h2 
          class="project-thumb__title t-hero t-hero--small">
          <span v-html="title"></span>
          <strong class="project-thumb__subtitle" v-if="subtitle" v-html="subtitle">
          </strong>
        </h2>
        <svg class="project-thumb__icon icon">
          <use xlink:href="#icon-arrow"></use>
        </svg>
      </div>
    </a>

    <div  
      class="project-thumb__clone js-clone"
      ref="clone"
    >
      <div class="project-thumb__mask js-clone-mask"></div>
      <img class="project-thumb__img project-thumb__img--hidden" v-bind:src="imgsrc" v-bind:alt="imgalt" />
      <div class="project-thumb__header js-clone-header">
        <h2 
          class="project-thumb__title t-hero t-hero--small js-clone-title">
          <span v-html="title"></span>
          <strong class="project-thumb__subtitle" v-if="subtitle" v-html="subtitle">
          </strong>
          <span class="project-thumb__refsizes js-clone-ref-sizes"></span>
        </h2>
        <svg class="project-thumb__icon icon js-clone-icon">
          <use xlink:href="#icon-arrow"></use>
        </svg>
      </div>
    </div>
  </article>
</template>

<script>
// https://codepen.io/meodai/pen/d7695bcddcb75435bde253d2f753d450
import anime from 'animejs/lib/anime.es.js';

export default {
  props: {
    imgsrc: String,
    imgalt: String,
    route: String,
    title: String,
    subtitle: String,
    url: String,
    related: Boolean,
  },
  mounted () {
    this.$clone = this.$refs.clone.cloneNode(true);
    this.$cloneMask = this.$clone.querySelector('.js-clone-mask');
    this.$cloneHeader = this.$clone.querySelector('.js-clone-header');
    this.$cloneTitle = this.$clone.querySelector('.js-clone-title');
    this.$cloneIcon = this.$clone.querySelector('.js-clone-icon');
    this.$refSizes = this.$clone.querySelector('.js-clone-ref-sizes');
    this.$body = document.querySelector('body');

    this.cloneDom = {
      $clone: this.$clone,
      $cloneMask: this.$cloneMask,
      $cloneHeader: this.$cloneHeader,
      $cloneTitle: this.$cloneTitle,
      $cloneIcon: this.$cloneIcon,
      $refSizes: this.$refSizes,
    };
  },
  methods: {
    goToDetail(event) {
      event.preventDefault();

      this.$clone.classList.add('project-thumb__clone--visible');
      this.$body.appendChild(this.$clone);

      const rootRect = this.$refs.root.getBoundingClientRect();
      
      const rootHeaderRect = this.$refs.header.getBoundingClientRect();
      this.$cloneHeader.style = `
        top: ${rootHeaderRect.top}px;
        left: ${rootHeaderRect.left}px;
        height: ${rootHeaderRect.height}px;
        width: ${rootHeaderRect.width}px;
      `;

      const cloneRect = this.$clone.getBoundingClientRect();
      this.$cloneMask.style.transform = `
        translateX(${rootHeaderRect.left - cloneRect.left}px) 
        translateY(${rootHeaderRect.top - cloneRect.top}px) 
        scaleX(${rootHeaderRect.width / cloneRect.width}) 
        scaleY(${rootHeaderRect.height / cloneRect.height})
      `;

      this.$cloneIcon.style.transform = `translateY(-50%)`;

      const refSizesRect = this.$refSizes.getBoundingClientRect();

      const tl = anime.timeline();

      tl.add({
        targets: this.$cloneMask,
        translateX: 0,
        translateY: 0,
        scaleX: 1,
        scaleY: 1,
        delay: 100,
        duration: 400,
        easing: 'cubicBezier(0.52, 0.01, 0.16, 1)',
      })
      .add({
        targets: this.$cloneTitle,
        translateX: cloneRect.left - rootHeaderRect.left + refSizesRect.width - refSizesRect.height,
        duration: 440,
        easing: 'cubicBezier(0.52, 0.01, 0.16, 1)',
      }, '-=400')
      .add({
        targets: this.$cloneIcon,
        translateX: cloneRect.right - rootHeaderRect.right,
        duration: 440,
        easing: 'cubicBezier(0.52, 0.01, 0.16, 1)',
        opacity: 0,
      }, '-=350');

      tl.finished.then(() => {
        this.$router.push({
          name: 'work',
          params: {
            case: this.route,
            cloneDom: this.cloneDom,
            cloneHeaderRect: rootHeaderRect,
          },
        });
      });
    }
  }
}
</script>

<style lang="scss">
  @import '../styles/_toolbox';

  .heightdiff {
    position: absolute;
    left: 0;
    bottom: 0;
    display: block;
    background: red;
    width: 1rem;
    // headerHeight + margin project header - padding title 
    height: calc(6rem + var(--size-content-stack) - 0.25em - var(--size-bezel) * 0.75);
  }

  .project-thumb {
    position: relative;
    height: 70vh;
    max-height: 86rem;
    
    @include viewport(phone) {
      height: 60vh;
      max-height: 66rem;
    }
    
    &--related {
      height: 60vh;
      max-height: 66rem;
      
      @include viewport(phone) {
        height: 50vh;
        max-height: 55rem;
      }

    }

    padding: var(--size-bezel);
    background-size: cover;
    background-position: 50% 50%;
    margin-bottom: calc(var(--size-grid-gutter));

    &__header {
      position: absolute;
      bottom: var(--size-bezel);
      right: var(--size-bezel);
      left: var(--size-bezel);
      background: var(--c-bg);
      padding: calc(var(--size-bezel) * .75);
      //min-height: 11rem;
      box-sizing: border-box;
    }

    &__link,
    &__clone {
      display: block;
    }

    &__refsizes {
      display: block;
      position: absolute;
      width: var(--size-design-gutter); // design gutter to mesure in js
      background: red;
      height: calc(var(--size-bezel) * .75); // header padding, to measure in js
    }

    &__clone {
      opacity: 0;
      z-index: 100;
      position: fixed;
      pointer-events: none;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      &--visible {
        opacity: 1;
      }

      .project-thumb__header {
        background: none;
      }
    }

    &__mask {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: var(--c-bg);
      transform-origin: 0 0;
    }

    &__title {
      display: block;
      margin: 0 25% 0 0;
      overflow: hidden;
      position: relative;
      z-index: 2;
    }

    &__subtitle {
      display: block;
      padding-bottom: 0.05em;
    }

    &__icon {
      position: absolute;
      top: 50%;
      right: calc(var(--size-bezel) * .75);
      transform: translateY(-50%);
      width: 5rem;
      height: auto;
      z-index: 2;
    }

    &__img {
      &--hidden {
        position: absolute;
        opacity: 0;
        width: 100%;
        height: 100%;
      }

      &--overlay {
        opacity: 0;
      }
    }
  }


  .project-thumb {
    transition: 200ms opacity linear;
    backdrop-filter: grayscale(100%);

    &:last-of-type {
      margin-bottom: 0;
    }

    &::after {
      pointer-events: none;
      opacity: 0;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(255,255,255,.2);
      -webkit-backdrop-filter: grayscale(100%);
      backdrop-filter: grayscale(100%);
      transition: 200ms opacity linear;
      will-change: opacity;
    }
  }

  
</style>