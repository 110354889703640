<template>
  <div>
    <MainHeader 
      :nav='routes'
      :isStatic='true'
    ></MainHeader>
    <div class="design-width content" v-if="contents.data">
      <Thumb
        v-for="content in contents.data.featuredWork"
        :key="content.slug"
        :route="content.slug"
        :imgsrc="content.image.url"
        :imgalt="content.image.title"
        :title="content.title"
        :subtitle="content.client"
      >
      </Thumb>

      <Related v-if="contents.data.relatedLink" 
        :route="`/${contents.data.relatedLink.slug}`" 
        :title="contents.data.relatedLink.title" 
        :imgsrc="contents.data.relatedLink.image.url" 
        :imgalt="contents.data.relatedLink.image.title"
      />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import {ax, baseURL} from "../js/api";
import Thumb from "../components/project-thumb.vue";
import Related from "../components/related.vue";
import MainHeader from "../components/header.vue";
import routes from "../js/routes";

export default Vue.extend({
  components: {
    Thumb,
    Related,
    MainHeader,
  },
  data: function () {
    return {
      contents: {},
      routes: routes,
    }
  },
  created() {
    ax.get(`${baseURL}/api/home`).then(response => {
      this.contents = response;
    })
    .catch(function (error) {
      //console.error(error);
    })
    .finally(function () {
      // always executed
    });
  },
});
</script>