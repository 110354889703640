




















import Vue from "vue";

export default {
  props: {
    isMini: Boolean,
    isMainTitle: Boolean,
  },
  methods: {
    goHomeYourDrunk () {
      this.$router.push({path: '/'});
    },
  }
}
