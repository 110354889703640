import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';

Vue.use(VueRouter);

const router = new VueRouter({
  routes,
  mode: 'history',
  base: '/',
  linkActiveClass: 'is-active',
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          x: 0,
          y: 0
        })
      }, 300);
    });
  },
});

router.onReady(() => {
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 300);
});


export default router;