<template>
  <div class="design-width content">
    <div class="grid grid--center grid">
      <div v-for="work in works.data" :key="work.slug" class="grid__cell grid__cell--1/2 grid__cell--1/1@phone">
        <Thumb
          v-bind:route=work.slug 
          v-bind:imgsrc=work.image.url
          v-bind:imgalt=work.image.title
          v-bind:title=work.title
          v-bind:subtitle=work.client
        >
        </Thumb>
      </div>
    </div>
  </div>

</template>

<script>
import Vue from "vue";
import Thumb from "../components/project-thumb.vue";
import {ax, baseURL} from "../js/api";

export default Vue.extend({
  components: {
    Thumb,
  },
  data: function () {
    return {
      works: [],
    }
  },
  created() {
    ax.get(`${baseURL}/api/work`).then(response => {
      // handle success
      this.works = response.data;
    })
    .catch(function (error) {
      //console.error(error);
    })
    .finally(function () {
      // always executed
    });
  }
});
</script>