
















































import Vue from "vue";
import Logo from "./components/logo.vue";
import Design from "./components/design.vue";
import MainHeader from "./components/header.vue";
import Video from "./components/video.vue";
import routes from "./js/routes";
import {ax, baseURL} from "./js/api";
import { assertSwitchCase } from "babel-types";
import anime from 'animejs/lib/anime.es.js';


export default Vue.extend({
  components: {
    Logo,
    MainHeader,
    Design,
    Video,
  },
  data() {
    return {
      name: "wrapper",
      isLogoVisible: false,
      navigationItems: routes,
      scrollProgress: 0,
      currentPage: '',
      videoCollapsed: false,
      footerTexts: [],
      contents: null,
      headerTxt: '',
      homeReady: false,
      loaded: false,
      letters: 'HOCHSPANNUNG'.split(''),
      letterIndex: 0,
      lastX: 0,
      lastY: 0,
      introMovieDuration: '1s',
      footerReady: false,
    };
  },
  metaInfo () { 
    return {
      title: 'Hochspannung – Werbeagentur Zürich',
      htmlAttrs: {
        lang: 'en',
      }
    }
  },
  mounted () {
    this.updateScrollHeight();
    this.startScrollListener();

    window.addEventListener('mousemove', this.drawtrail, true);

    document.body.addEventListener('load', () => {
      this.updateScrollHeight();
    }, true);

    window.addEventListener('resize', () => {
      this.updateScrollHeight();  
    });

    if ( this.isHome && this.$refs.homevideo ) {
      this.$refs.homevideo.addEventListener('loadeddata', (e) => {

        this.animateHero();

        if (e.target && e.target.duration ) {
          
          const tl = anime.timeline();
          
          tl.add({
            targets: this.$refs.videoTimeline,
            scaleX: 0,
            duration: e.target.duration * 1000,
            easing: 'linear',
          });
        }

        setTimeout(() => {
          this.loaded = true;
        }, 1000);
      }, true);
    } else {
        setTimeout(() => {
          this.loaded = true;
        }, 1000);
    }
  },
  created() {
    ax.get(`${baseURL}/api/footer`).then(response => {
      this.footerTexts = response.data.footerTexts;
      this.footerReady = true;
    })

    ax.get(`${baseURL}/api/home`).then(response => {
      this.contents = response.data;
      
      this.headerTxt = response.data.headerText;
    })
    .catch(function (error) {
      //console.error(error);
    })
    .finally(() => {
      this.homeReady = true;
      // always executed
    });

    ax.get(`${baseURL}/api/meta`).then(response => {
      document.querySelector('meta[name=description]').setAttribute('content', response.data.description);
      document.querySelector('meta[property="og:description"]').setAttribute('content', response.data.description);
      document.querySelector('meta[property="twitter:description"]').setAttribute('content', response.data.description);
    });
  },
  methods: {
    drawtrail: function (e) {
      if (!e.target.classList.contains('related') && !e.target.closest('.related')) return;

      const x = e.clientX;
      const y = e.clientY;

      const a = x - this.lastX;
      const b = y - this.lastY;

      const dist = Math.sqrt( a*a + b*b );

      if( dist < 40 ) return;

      this.lastX = x;
      this.lastY = y;

      if (this.letterIndex > this.letters.length) {
        this.letterIndex = 0;
      }

      const letter = this.letters[this.letterIndex];

      const $letter = document.createElement('div');
      $letter.textContent = letter;
      $letter.classList.add('floatyletter')
      $letter.style.setProperty('top', `${y}px`);
      $letter.style.setProperty('left', `${x}px`);

      document.documentElement.appendChild($letter);
      
      const tl = anime.timeline();
      tl.add({
        targets: $letter,
        scale: 0,
        opacity: 0,
        duration: 10,
      })

      tl.add({
        opacity: 1,
        targets: $letter,
        scale: 1,
        duration: 1000,
        translateY: -50,
        easing: 'cubicBezier(0.52, 0.01, 0.16, 1)', 
      });

      tl.add({
        targets: $letter,
        scale: 0,
        opacity: 0,
        duration: 200,
        translateY: 100,
        easing: 'cubicBezier(0.52, 0.01, 0.16, 1)',
      })

      tl.finished.then(() => {
        document.documentElement.removeChild($letter);
      });

      this.letterIndex++;

    },
    animateHero: function () {
      const $frags = this.$refs.hero.querySelector('.homevideo__textfragments');
      let children = [];
      let elements = 0;

      if ($frags) {
        children = Array.from($frags.children);
        elements = children.length;

        children.forEach((child, i) => {
          setTimeout(() => {
            if (children[i - 1]) {
              children[i - 1].classList.remove('visible');
            }
            children[i].classList.add('visible');
          }, i * 50);
        });

      };

      const $textEnd = this.$refs.hero.querySelector('.homevideo__textend');

      if ($textEnd && elements) {
        $textEnd.style.display = 'block';
        
        setTimeout(() => {
          $textEnd.classList.add('homevideo__textend--show');
        }, elements * 50);
      };

    },
    collapse: function () {
      this.videoCollapsed = true;
    },
    updatePageType: function () {
      if ( this.$route.path == '/' ) {
        this.currentPage = 'home';
      } else {
        this.currentPage = this.$route.name;
      }
    },
    startScrollListener: function () {
      let last_known_scroll_position = 0;
      let ticking = false;

      const updateScroll = (scroll_pos) => {
        this.scrollProgress = scroll_pos / (this.scrollHeight - this.winHeight);
        window.scrollProgress = this.scrollProgress;
        window.scrollTop = scroll_pos;
        window.scrollHeight = this.scrollHeight;
      };

      window.addEventListener('scroll', function(e) {
        last_known_scroll_position = window.scrollY;

        if (!ticking) {
          window.requestAnimationFrame(function() {
            updateScroll(last_known_scroll_position);
            ticking = false;
          });

          ticking = true;
        }
      });
    },
    updateScrollHeight: function () {
      this.scrollHeight = document.querySelector('body').scrollHeight;
      this.winHeight = window.innerHeight;
    },
    toggle: function () {
      this.isLogoVisible = !this.isLogoVisible;
    },
  },
  computed: {
    headerText: function () {
      if (this.contents) {
        return this.headerTxt;
      } else {
        return '';
      }
    },
    heroText: function() {
      //[sit,amet,bla,hallo,test]
      const bracketsReg = new RegExp(/\[(.*?)\]/);
      const fadeinReg = new RegExp(/\((.*?)\)/);

      let introTextHTML = this.contents.heroText || '';

      if ( this.contents.heroText && bracketsReg.test(this.contents.heroText) ) {
        let textfragments = bracketsReg.exec(this.contents.heroText);
        let textfragment = `<span class="homevideo__textfragments"><span class="visible">${textfragments[1].replace(/,/g, '</span><span>')}</span></span>`;
        introTextHTML = this.contents.heroText.replace(bracketsReg, textfragment);
      } 

      if ( this.contents.heroText && fadeinReg.test(this.contents.heroText) ) {
        let textfragments = fadeinReg.exec(this.contents.heroText);
        let textfragment = introTextHTML.replace(textfragments[0], `<span class="homevideo__textend">${textfragments[1]}</span>`);
        introTextHTML = textfragment;
      }

      return introTextHTML;
    },
    isHome: function() {
      return this.$route.path === '/';
    },
    footerText: function() {
      let response = null;
      
      if (this.footerTexts.length) {
        response = this.footerTexts.find(ft => ft.slug === this.$route.path );

        if (!response) {
          response = this.footerTexts.find(ft => this.$route.path.includes(ft.slug) );
        }
      }

      if (response) {
        return response.text;
      } else {
        return '';
      }
    },
  }
});
