<template>
  <div class="design-width content">
    <div class="sectionIntro">
      <Video 
        v-if="this.contents.data && (this.contents.data.coverImage || this.contents.data.coverVideo)"
        class="sectionIntro__video"
        :src="this.contents.data.coverVideo" 
        :poster="this.contents.data.coverImage.url" 
        :autoplay="true" 
        :loop="true"
        :muted="true">
      </Video>
    </div>

    <p
      v-if="this.contents.data"
      class="content__block content__block--intro content__block--narrow t-medium"
      v-html="this.contents.data.leadText"
    ></p>

    <h1 
      v-if="this.contents.data"
      class="t-title t-title--case t-hero" 
      v-html="this.contents.data.teamTitle"
    ></h1>

    <div ref="teammembers" class="team-members">
      <div v-if="this.contents.data">
        <div 
          v-for="(content, index) in contents.data.teamMembers" 
          :key="index"
          class="content__block"
        >
          <TeamMember
            :url="'mailto:' + content.email"
            :imgsrc="content.image"
            :videosrc="content.video"
            :imgalt="content.name + ' — '  + content.function"
            :title="content.name"
            :subtitle="content.function"
            :i = index
            :intersections="intersections" 
            ref="teammember"
          ></TeamMember>
        </div>
      </div>
    </div>

    <Related v-if="this.contents.data && contents.data.relatedLink" 
        :route="`/${contents.data.relatedLink.slug}`" 
        :title="contents.data.relatedLink.title" 
        :imgsrc="contents.data.relatedLink.image.url" 
        :imgalt="contents.data.relatedLink.image.title"
    />
  </div>
</template>

<script>
import Vue from "vue";
import Video from "../components/video.vue";
import {ax, baseURL} from "../js/api";
import TeamMember from "../components/team-member.vue";
import Related from "../components/related.vue";

export default Vue.extend({
  components: {
    Video,
    TeamMember,
    Related,
  },
  data: function () {
    return {
      contents: {
        video: '',
        teamCoverImage: {url: ''},
        leadText: '',
      },
      intersections: [],
    }
  },
  mounted () {
    this.observer = new IntersectionObserver(this.areInView, {
      threshold: [.25,.5,.75],
    });
    
    //window.addEventListener('resize', this.measureElement({target: this.$refs.video}));
    
    this.$refs.teammembers.addEventListener('loadeddata', this.observe, true);
    
    //window.removeEventListener('scroll', this.scrollListener);
    //window.removeEventListener('resize', this.measureElement);

  },
  created() {
    ax.get(`${baseURL}/api/team`).then(response => {
      this.contents = response;
    });
  },
  methods: {
    observe: function ($el) {
      this.intersections.push(0);
      this.observer.observe($el.target);
    },
    areInView: function (entries) {
      entries.forEach(entry => {
        const index = parseInt(entry.target.getAttribute('data-index'));

        if (entry.isIntersecting) {
          this.intersections[index] = entry.intersectionRatio;
          this.$refs.teammember[index].anim();
        } else {
          this.intersections[index] = 0;
        }
      });
    },
    measureElements: function () {

    }
  },
})
</script>